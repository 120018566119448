var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-sheet',{attrs:{"outlined":"","rounded":""}},[_c('editor-menu-bar',{attrs:{"editor":_vm.editor},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var commands = ref.commands;
var isActive = ref.isActive;
return [_c('div',[_c('v-tooltip',{attrs:{"top":"","color":"#2222BF"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:{ 'v-btn--active': isActive.bold() },attrs:{"icon":"","tile":""},on:{"click":commands.bold}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20","color":"#757575"}},[_vm._v("mdi-format-bold")])],1)]}}],null,true)},[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("Bold")])]),_c('v-tooltip',{attrs:{"top":"","color":"#2222BF"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:{ 'v-btn--active': isActive.italic() },attrs:{"icon":"","tile":""},on:{"click":commands.italic}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20","color":"#757575"}},[_vm._v("mdi-format-italic")])],1)]}}],null,true)},[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("Itálica")])]),_c('v-tooltip',{attrs:{"top":"","color":"#2222BF"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:{ 'v-btn--active': isActive.underline() },attrs:{"icon":"","tile":""},on:{"click":commands.underline}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20","color":"#757575"}},[_vm._v("mdi-format-underline")])],1)]}}],null,true)},[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("Subrayado")])]),_c('v-tooltip',{attrs:{"top":"","color":"#2222BF"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:{ 'v-btn--active': isActive.bullet_list() },attrs:{"icon":"","tile":""},on:{"click":commands.bullet_list}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20","color":"#757575"}},[_vm._v("mdi-format-list-bulleted")])],1)]}}],null,true)},[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("Lista no ordenada")])]),_c('v-tooltip',{attrs:{"top":"","color":"#2222BF"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:{ 'v-btn--active': isActive.ordered_list() },attrs:{"icon":"","tile":""},on:{"click":commands.ordered_list}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20","color":"#757575"}},[_vm._v("mdi-format-list-numbered")])],1)]}}],null,true)},[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("Lista ordenada")])]),_c('v-tooltip',{attrs:{"top":"","color":"#2222BF"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:{ 'v-btn--active': isActive.blockquote() },attrs:{"icon":"","tile":""},on:{"click":commands.blockquote}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20","color":"#757575"}},[_vm._v("mdi-format-quote-close")])],1)]}}],null,true)},[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("Cita")])]),_c('v-tooltip',{attrs:{"top":"","color":"#2222BF"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","tile":""},on:{"click":commands.horizontal_rule}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20","color":"#757575"}},[_vm._v("mdi-minus")])],1)]}}],null,true)},[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("Línea horizontal")])]),_c('v-tooltip',{attrs:{"top":"","color":"#2222BF"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:{ 'v-btn--active': isActive.code_block() },attrs:{"icon":"","tile":""},on:{"click":commands.code_block}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20","color":"#757575"}},[_vm._v("mdi-code-tags")])],1)]}}],null,true)},[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("Bloque de código")])]),_c('v-tooltip',{attrs:{"top":"","color":"#2222BF"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:{ 'v-btn--active': isActive.link() },attrs:{"icon":"","tile":""},on:{"click":function($event){return _vm.setUrl()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20","color":"#757575"}},[_vm._v("mdi-link-variant")])],1)]}}],null,true)},[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("Hipervínculo")])]),_c('v-tooltip',{attrs:{"top":"","color":"#2222BF"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","tile":""},on:{"click":function($event){return _vm.clickFileInput()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20","color":"#757575"}},[_vm._v("mdi-attachment")])],1)]}}],null,true)},[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("Subir archivos")])])],1)]}}])}),_c('v-divider'),_c('div',{staticClass:"pa-2",staticStyle:{"min-height":"180px"},on:{"click":_vm.focusEditor}},[_c('editor-content',{attrs:{"editor":_vm.editor}})],1),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.popover)?_c('div',{staticClass:"py-2 px-3",staticStyle:{"height":"56px"}},[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":"10"}},[_c('v-text-field',{attrs:{"autofocus":"","dense":"","hide-details":"","filled":"","rounded":""},model:{value:(_vm.popover_link),callback:function ($$v) {_vm.popover_link=$$v},expression:"popover_link"}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"icon":"","color":"#181818"},on:{"click":function($event){return _vm.closeLink()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-col',{attrs:{"cols":"1"}},[_c('editor-menu-bar',{attrs:{"editor":_vm.editor},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var commands = ref.commands;
return [_c('v-btn',{attrs:{"icon":"","color":"#2222BF"},on:{"click":function($event){return _vm.saveLink(commands.link)}}},[_c('v-icon',[_vm._v("mdi-check")])],1)]}}],null,false,525641150)})],1)],1)],1):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }