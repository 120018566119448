<template>
  <div class="text-center">
    <v-dialog :value="modal" :width="modal_type.delete_log ? '500' : '811'" persistent>
      <v-card class="px-7 py-6">
        <v-btn icon absolute right top color="#181818" @click="closeModal"><v-icon>mdi-close</v-icon></v-btn>
          <log-detail v-if="modal_type.log_detail"/>
          <note-pad v-if="modal_type.notepad"></note-pad>
          <delete-log v-if="modal_type.delete_log"></delete-log>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import LogDetail from '../Dialogs/LogDetail'
import NotePad from '../Dialogs/NotePad'
import DeleteLog from '../Dialogs/DeleteLog'

export default {
  components: {
    LogDetail,
    NotePad,
    DeleteLog
  },
  computed: mapState(['modal', 'modal_type']),
  methods: mapActions(['closeModal'])
}
</script>
