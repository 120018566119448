import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

let WEB_HOST = window.location.host.includes('localhost') ? 'http://localhost:9000' : 'https://' + window.location.host.substring(8)

const BASE_URL = `${WEB_HOST}/api`

function setInStorage(key, value){
  localStorage.setItem(key, value)
}

function getFromStorage(key){
  return localStorage.getItem(key)
}

function defaultLog(){
  return{
    title: null,
    category: null,
    anotation: null,
    status: null
  }
}

export default new Vuex.Store({
  state: {
    //
    token: getFromStorage('token') || null,
    header: null,
    //General
    company: null,
    modal: false,
    current_log: null,
    modal_type: {
      notepad: false,
      log_detail: false,
      delete_log: false,
    },
    loading: false,
    page: 1,
    search_results: [],
    // ClientLog
    new_log: defaultLog(),
    new_log_files: [],
    files_to_delete: [],
    categories_items: [],
    categories: [],
    status: [],
    logs: [],
    finances: [],
    //Implementación
    services_info: [],
    //Commercial
    deals: null,
    //Resumen
    summary: null
  },
  mutations: {
    //General
    SET_TOKEN(state, token){state.token = token},
    SET_SEARCH_RESULTS(state, search){state.search_results = search},
    SET_COMPANY(state, company){state.company = company},
    SET_HEADER(state){state.header = {headers: {'x-talana-jwt' : state.token}}},
    UPDATE_PAGE(state, page){state.page = page},
    RESET_PAGE(state){state.page = 1},
    OPEN_MODAL(state){state.modal = true},
    CLOSE_MODAL(state){state.modal = false},
    SET_MODAL_TYPE(state, type){
      Object.keys(state.modal_type).forEach((t) => {
        t == type ? state.modal_type[t] = true : state.modal_type[t] = false
      })
    },
    RESET_MODAL_TYPES(state){
      Object.keys(state.modal_type).forEach((t) => state.modal_type[t] = false)
    },
    SHOW_LOADING(state){state.loading = true},
    HIDE_LOADING(state){state.loading = false},
    //ClientLog
    SET_CURRENT_LOG(state, log){
      const l = log
      state.current_log = l
    },
    SET_CATEGORIES(state, cat){ state.categories = cat },
    SET_CATEGORIES_ITEMS(state, categories){
      let header = { header: "Selecciona una categoría" }
      state.categories_items = [ header, ...categories]
    },
    SET_STATUS(state, status){
      let header = { header: "Selecciona el nivel de felicidad"}
      state.status = [header, ...status]
    },
    SET_LOGS(state, logs){ state.logs = logs },
    UPDATE_LOG_TITLE(state, title){ state.new_log.title = title},
    UPDATE_LOG_CATEGORY(state, category){ state.new_log.category = category},
    UPDATE_LOG_ANOTATION(state, anotation){ state.new_log.anotation = anotation},
    UPDATE_LOG_FILES(state, files){state.new_log_files = files},
    UPDATE_LOG_STATUS(state, status){state.new_log.status = status},
    FILES_TO_DELETE(state, files){state.files_to_delete = files},
    RESET_NEW_LOG(state){
      state.new_log = defaultLog()
      state.new_log_files = []
    },
    EDIT_LOG(state, log){ state.new_log = log},
    //Finances
    SAVE_FINANCES(state, finances){state.finances = finances},
    //Implementación
    SERVICES_INFO(state, info){state.services_info = info},
    //Commercial
    SAVE_DEALS(state, deals){state.deals = deals.results},
    //Resumen
    SAVE_SUMMARY(state, summary){state.summary = summary}
  },
  actions: {
    //General
    getCompaniesBySearch({commit,state}, search){
      axios.get(`${BASE_URL}/cliente/company/search/?name=${search}`, state.header)
      .then(response => commit('SET_SEARCH_RESULTS', response.data.results))
    },
    saveToken({commit}, token){
      setInStorage('token', token)
      commit('SET_TOKEN', token)
      commit('SET_HEADER')
    },
    setHeader({commit, dispatch}){
      commit('SET_HEADER')
      dispatch('getCompanyInfo')
    },
    getToken({state,  dispatch} ,id){
      axios.get(`${BASE_URL}/cliente/get-token?company=${id}`, state.header)
      .then((response) => {
        dispatch('saveToken', response.data.token)
      })
      .then(()=> dispatch('resetSearchItems'))
      .then(()=> dispatch('getSearchInfo'))
    },
    getSearchInfo({commit, dispatch}){
      commit('SET_LOGS', [])
      commit('SAVE_FINANCES', [])
      commit('SAVE_DEALS', {results: null})
      if(location.hash=='#/bitacora'){dispatch('getLogs')}
      if(location.hash=='#/finanzas'){dispatch('getFinances')}
      if(location.hash=='#/comercial'){dispatch('getCommercialDeals')}
      if(location.hash=='#/resumen'){dispatch('getSummary')}
    },
    getCompanyInfo({state, commit}){
      axios.get(`${BASE_URL}/cliente/company`, state.header)
      .then((response)=> {
        commit('SET_COMPANY', response.data)
      })
    },
    updatePage({commit}, page){commit('UPDATE_PAGE', page)},
    scrollToTop(){ window.scrollTo({top: 0, behavior: 'smooth'})},
    openDetailLog({commit}, data){
      commit('OPEN_MODAL')
      commit('SET_CURRENT_LOG', data)
      commit('SET_MODAL_TYPE', 'log_detail')
    },
    openNotePad({commit}){
      commit('OPEN_MODAL')
      commit('SET_MODAL_TYPE', 'notepad')
    },
    closeModal({commit, dispatch}){
      commit('CLOSE_MODAL')
      dispatch('resetAll')
    },
    resetAll({commit}){
      commit('SET_CURRENT_LOG', null)
      commit('RESET_MODAL_TYPES')
      commit('RESET_NEW_LOG')
      commit('FILES_TO_DELETE', [])
    },
    resetSearchItems({commit}){
      commit('SET_SEARCH_RESULTS', [])
    },
    //ClientLog
    getCategories({commit, getters, state}){
      axios.get(`${BASE_URL}/cliente/bitacora/categories/`, state.header)
      .then( response => {
        let cat = response.data
        cat.forEach(cat => cat.name = getters.capitalizeFirstLetter(cat.name))
        commit('SET_CATEGORIES', cat)
        commit('SET_CATEGORIES_ITEMS', cat)
      })
    },
    getStatus({commit, getters, state}){
      axios.get(`${BASE_URL}/cliente/bitacora/status`, state.header)
      .then( response => {
        let status = response.data
        status.forEach(st => st.name = getters.capitalizeFirstLetter(st.name))
        commit('SET_STATUS', status)
      })
    },
    getLogs({commit, dispatch, state}, filter){
      let f = filter ? filter : ''
      dispatch('scrollToTop')
      dispatch('getCompanyInfo')
      commit('SHOW_LOADING')
      axios.get(`${BASE_URL}/cliente/bitacora/${f}`, state.header)
      .then( response => {
        dispatch('getCategories')
        commit('SET_LOGS', response.data)
        commit('HIDE_LOADING')
      })
    },
    getLogsByPage({dispatch}, page){
      let p = page ? `?page=${page}` : ''
      dispatch('getLogs', p)
    },
    getByCategory({dispatch}, category){
      let cat = category>0 ? `?category=${category}` : ''
      dispatch('getLogs', cat)
    },
    postLog({state, dispatch, commit}){
      commit('SHOW_LOADING')
      commit('CLOSE_MODAL')

      let data_no_state = { title: state.new_log.title, anotation: state.new_log.anotation, category: state.new_log.category}
      let data = state.new_log.status ? state.new_log : data_no_state

      axios.post(`${BASE_URL}/cliente/bitacora/`, data, state.header)
        .then(response => {
          if(state.new_log_files.length>0){
            dispatch('postFiles',response.data.id)
          } else{
            commit('HIDE_LOADING')
            commit('RESET_PAGE')
            dispatch('getLogs')
            dispatch('resetAll')
          }
        })
        .catch(error => console.log(error))
    },
    postFiles({state, dispatch, commit}, id){
      state.new_log_files.forEach((file,i) => {
        let file_data = new FormData()
        file_data.append("bitacora", id)
        file_data.append("file", file)

        axios.post(`${BASE_URL}/cliente/bitacora/attached-files/`, file_data, state.header)
        .then(() => {
          if(i == state.new_log_files.length-1){
            commit('HIDE_LOADING')
            commit('RESET_PAGE')
            dispatch('getLogs')
            dispatch('resetAll')
          }
        })
        .catch(err => console.log(err))
      })
    },
    editLog({commit}, log){
      const editlog = {...log}
      commit('EDIT_LOG', editlog)
      commit('SET_MODAL_TYPE', 'notepad')
      commit('SET_CURRENT_LOG', null)
    },
    deleteAlert({commit}){
      commit('SET_MODAL_TYPE', 'delete_log')
    },
    deleteLog({commit, dispatch, state}){
      commit('SHOW_LOADING')
      commit('CLOSE_MODAL')
      axios.delete(`${BASE_URL}/cliente/bitacora/${state.current_log.id}`, state.header)
        .then(() => {
          commit('HIDE_LOADING')
          commit('RESET_PAGE')
          dispatch('getLogs')
          dispatch('resetAll')
        })
    },
    putLog({state, dispatch, commit}){
      commit('SHOW_LOADING')
      commit('CLOSE_MODAL')

      let put_data = { title: state.new_log.title, anotation: state.new_log.anotation, category: state.new_log.category}
      let data_state = { status: state.new_log.status }
      let data = state.new_log.status ? { ...put_data, ...data_state } : put_data

      axios.put(`${BASE_URL}/cliente/bitacora/${state.new_log.id}/`, data, state.header)
        .then(() => {
          if(state.files_to_delete.length>0){
            dispatch('deleteFiles')
          }
          else{
            dispatch('checkPostFiles')
          }
        })
        .catch(error => console.log(error))
    },
    checkPostFiles({state, dispatch, commit}){
      if(state.new_log_files.length>0){
        dispatch('postFiles',state.new_log.id)
      } else{
        commit('RESET_PAGE')
        commit('HIDE_LOADING')
        dispatch('getLogs')
        dispatch('resetAll')
      }
    },
    deleteFiles({state, dispatch}){
      if(state.files_to_delete){
        state.files_to_delete.forEach(file =>
          axios.delete(`${BASE_URL}/cliente/bitacora/attached-files/${file}`, state.header)
          .then(() => dispatch('checkPostFiles') )
          .catch(err => console.log(err)))
      }
    },
    setFilesToDelete({commit}, files){ commit('FILES_TO_DELETE', files)},
    updateLogTitle({commit}, title){ commit('UPDATE_LOG_TITLE', title) },
    updateLogAnotation({commit}, anotation){ commit('UPDATE_LOG_ANOTATION', anotation)},
    updateLogCategory({commit}, category){ commit('UPDATE_LOG_CATEGORY', category) },
    updateLogFiles({commit}, files){ commit('UPDATE_LOG_FILES', files) },
    updateLogStatus({commit}, status){ commit('UPDATE_LOG_STATUS', status)},
    //Finances
    getFinances({state, dispatch, commit}, page){
      let p = page ? page : ''
      commit('SHOW_LOADING')
      dispatch('getCompanyInfo')
      axios.get(`${BASE_URL}/cliente/finanzas/${p}`, state.header)
      .then((response) => {
        commit('SAVE_FINANCES', response.data)
        commit('HIDE_LOADING')
      })
      .catch(error => console.log(error))
    },
    getFinancesByPage({dispatch}, page){
      let p = page ? `?page=${page}` : ''
      dispatch('getFinances', p)
    },
    //Implementación
    getServicesInfo({commit, dispatch, state}){
      dispatch('getCompanyInfo')
      axios.get(`${BASE_URL}/cliente/implementacion`, state.header)
      .then((response) => {
        commit('SERVICES_INFO',response.data)
      })
    },
    //Commercial
    getCommercialDeals({commit, dispatch, state}){
      commit('SHOW_LOADING')
      dispatch('getCompanyInfo')
      axios.get(`${BASE_URL}/cliente/commercial`, state.header)
      .then(response => {
        commit('SAVE_DEALS', response.data)
        commit('HIDE_LOADING')
      })
    },
    //Resumen
    getSummary({commit, dispatch, state}) {
      commit('SHOW_LOADING')
      dispatch('getCompanyInfo')
      axios.get(`${BASE_URL}/cliente/summary`, state.header)
      .then(response => {
        commit('SAVE_SUMMARY', response.data)
        commit('HIDE_LOADING')
      })
    }
  },
  getters: {
    capitalizeFirstLetter: () => (string) => string.charAt(0).toUpperCase() + string.slice(1),
    formattedNumber: () => (number) => {
      const format = new Intl.NumberFormat('es-ES');
      return format.format(number)
    }
  }
})
