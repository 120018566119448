<template>
  <div>
    <p class="hidden-md-and-up mb-1">
      <v-chip
        x-small
        class="px-2 mr-2"
        style="font-size: 11px"
        dark
        v-if="current_log.status"
        :color="current_log.status.color"
        >{{ capitalizeFirstLetter(current_log.status.name) }}</v-chip
      >
      <span class="font-weight-medium" style="color: #2222BF; font-size: 11px">{{capitalizeFirstLetter(current_log.category.name)}}</span>
    </p>
    <p style="font-size: 12px; color: #757575" class="mb-md-0 ml-md-12">
      <v-chip
        x-small
        class="px-2 mb-1 mr-3 hidden-sm-and-down"
        style="font-size: 11px"
        dark
        v-if="current_log.status"
        :color="current_log.status.color"
        >{{ capitalizeFirstLetter(current_log.status.name) }}</v-chip
      >
      Publicado el {{ current_log.created | moment("DD/MM/YYYY, kk:mm") }}
      <span class="ml-4 font-weight-medium hidden-sm-and-down" style="color: #2222BF; font-size: 11px">{{capitalizeFirstLetter(current_log.category.name)}}</span>
    </p>
    <div class="d-flex mb-2 align-center">
      <v-avatar size="36" :color="current_log.author.personadetalle_set.length>0 && current_log.author.personadetalle_set[0].foto ? '': '#2222BF'">
        <v-img :src="current_log.author.personadetalle_set[0].foto" v-if="current_log.author.personadetalle_set.length>0 && current_log.author.personadetalle_set[0].foto"></v-img>
        <span class="white--text" v-else>{{current_log.author.nombre[0]}}{{current_log.author.apellidoPaterno[0]}}</span>
      </v-avatar>
      <div class="ml-3">
        <p class="mb-0 font-weight-medium mt-1" style="font-size: 14px">
          {{ current_log.author.nombre }} {{ current_log.author.apellidoPaterno}} {{ current_log.author.apellidoMaterno }}
        </p>
      </div>
    </div>
    <div class="ml-md-12">
      <p class="font-weight-bold mb-3">
        {{ capitalizeFirstLetter(current_log.title) }}
      </p>
      <p style="font-size: 14px" v-html="current_log.anotation"></p>
    </div>
    <v-divider class="mt-10 mb-4" v-if="current_log.files.length>0"></v-divider>
    <p v-if="current_log.files.length>0" class="font-weight-medium" style="font-size: 13px; color: #757575">{{current_log.files.length}} {{current_log.files.length>1 ? 'archivos adjuntos' : 'archivo adjunto'}}</p>
    <v-row v-if="current_log.files.length>0">
      <v-col cols="6" md="3" v-for="(file, i) in current_log.files" :key="i">
        <v-card flat outlined class="pa-0" @click="openFile(file.file)">
          <v-img class="text-center align-center" height="90px" :src="file.file">
            <v-icon x-large color="#DEDEDE" class="mt-2" v-if="checkFileType(file.file)">mdi-file</v-icon>
          </v-img>
          <v-row no-gutters align="center" style="background: #f5f5f5">
            <v-col cols="2" class="text-right"><v-icon small color="#2222BF" class="mr-1">mdi-file</v-icon></v-col>
            <v-col cols="10" class="mt-1"><p style="font-size: 11px" class="mb-0">{{oneLineName(file.file)}}</p></v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-card-actions class="ma-0 pa-0" v-if="current_log.edit">
      <v-spacer></v-spacer>
      <v-btn icon color="#757575" class="mr-2" @click="deleteAlert"><v-icon>mdi-delete</v-icon></v-btn>
      <v-btn icon color="#757575" class="mx-0" @click="editLog(current_log)"><v-icon>mdi-pencil</v-icon></v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  computed: {
    ...mapState(["current_log"]),
    ...mapGetters(["capitalizeFirstLetter"])
  },
  methods: {
    oneLineName(name){
      if(window.innerWidth>960){
        if(name.length<=25){return name}
        return name.slice(0,12)+'...'+name.slice(name.length-10)
      }else{
        if(name.length<=20){return name}
        return name.slice(0,8)+'...'+name.slice(name.length-8,name.length)
      }
    },
    openFile(file){
      window.open(file)
    },
    checkFileType(file){
      let ext = file.split(/[#?]/)[0].split('.').pop().trim()
      if(ext == "jpg" || ext == "jpeg" || ext == "png" || ext == "gif"){
        return false
      }
      return true
    },
    ...mapActions(['editLog','deleteAlert'])
  },
};
</script>
