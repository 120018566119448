import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
     path: '/',
     name: 'Home',
     component: () => import(/* webpackChunkName: "client-log" */ '../views/Index')
  },
  // {
  //   path: '/nueva-nota',
  //   name: 'NewLog',
  //   component: () => import(/* webpackChunkName: "new-log" */ '../views/NewLog')
  // },
  {
    path: '/resultados',
    name: 'Results',
    component: () => import(/* webpackChunkName: "client-log" */ '../views/Results'),
    children: [
      {
        path: '/resumen',
        alias: '/resultados',
        name: 'Summary',
        component: () => import(/* webpackChunkName: "finances" */ '../views/Summary')
      },
      {
        path: '/comercial',
        name: 'Commercial',
        component: () => import(/* webpackChunkName: "commercial" */ '../views/Commercial')
      },
      // {
      //   path: '/implementacion',
      //   name: 'Implementation',
      //   component: () => import(/* webpackChunkName: "implementation" */ '../views/Implementation')
      // },
      {
        path: '/finanzas',
        name: 'Finances',
        component: () => import(/* webpackChunkName: "finances" */ '../views/Finances')
      },
      {
        path: '/bitacora',
        name: 'ClientLog',
        alias: '/bitacora-form',
        component: () => import(/* webpackChunkName: "client-log" */ '../views/ClientLog')
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
