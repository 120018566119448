<template>
  <div class="text-center">
    <v-icon size="60" class="mb-2" color="#2222BF">mdi-alert-circle</v-icon>
    <p class="mb-1">¿Estás seguro que deseas borrar <span class="font-weight-bold">{{capitalizeFirstLetter(current_log.title)}}</span>?</p>
    <p style="color: #757575; font-size: 14px">Este cambio es irreversible.</p>
    <v-card-actions class="pa-0 justify-center">
      <v-btn class="text-none mr-2 px-3 font-weight-regular" color="#181818" small outlined @click="closeModal">Cancelar</v-btn>
      <v-btn class="text-none px-4 font-weight-regular" dark small color="#2222BF" depressed @click="deleteLog">Aceptar</v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['current_log']),
    ...mapGetters(['capitalizeFirstLetter'])
  },
  methods: mapActions(['closeModal', 'deleteLog'])
}
</script>
