<template>
  <div>
    <p class="font-weight-medium">{{ new_log.id ? 'Editar nota':'Nueva nota'}}</p>
    <v-row>
      <v-col cols="12" md="8">
        <v-text-field
          label="Título de la nota"
          solo
          background-color="#F1F1F8"
          flat
          dense
          height="40"
          @input="updateLogTitle"
          :value="new_log.title"
          :rules="[rules.required, rules.maxTitle]"
        ></v-text-field>
        <p class="font-weight-medium mb-2" style="font-size: 13px; color: #757575">
          Descripción
        </p>
        <text-editor></text-editor>
      </v-col>
      <v-col cols="12" md="4">
        <v-select
          :items="categories_items"
          placeholder="Categorías"
          outlined
          flat
          dense
          color="#2222BF"
          menu-props="offsetY"
          item-color="indigo"
          item-text="name"
          item-value="id"
          :value="new_log.category"
          @input="updateLogCategory"
          :rules="[rules.required]"
          hide-details
          class="mb-3"
        >
        </v-select>

        <v-select
          :items="status"
          placeholder="Nivel de felicidad"
          :background-color="selectStatusColor"
          outlined
          flat
          dense
          clearable
          :dark="new_log.status!=null"
          color="#2222BF"
          item-color="indigo"
          menu-props="offsetY"
          item-text="name"
          item-value="id"
          :value="new_log.status"
          @input="updateLogStatus"
        >
        </v-select>
      </v-col>
    </v-row>
    <file-input></file-input>
    <v-divider class="mt-6 mb-4"></v-divider>
    <v-card-actions class="pa-0">
      <v-alert dense text type="error" v-if="error" class="ma-0" height="36" style="font-size: 14px">{{error_msg}}</v-alert>
      <v-spacer></v-spacer>
      <v-btn
        class="text-none mr-2 px-3 font-weight-regular"
        color="#181818"
        outlined
        @click="closeModal"
        >Cancelar</v-btn
      >
      <v-btn
        class="text-none px-4 font-weight-regular"
        dark
        color="#2222BF"
        depressed
        @click="checkFields"
        >{{ new_log.id ? 'Guardar':'Aceptar'}}</v-btn
      >
    </v-card-actions>
  </div>
</template>

<script>
import { mapActions, mapState} from 'vuex';
import FileInput from './NotePad/FileInput'
import TextEditor from './NotePad/TextEditor'

export default {
  components: {
    FileInput,
    TextEditor,
  },
  data: () => ({
    options: [],
    error: false,
    error_msg: '',
    rules: {
      required: value => !!value || 'Campo obligatorio.',
      maxTitle: value => !!value && value.length<=100 || 'Máximo 100 caracteres.'
    }
  }),
  methods: {
    checkFields(){
      this.error = false
      if(this.new_log.title != null && this.new_log.title != "" && this.new_log.title.length<=100 && this.new_log.anotation != null && this.new_log.anotation != "<p></p>" && this.new_log.category != null){
        this.sendLog()
      } else{
        if(this.new_log.title.length>100){
          this.error_msg = 'Revisa el máximo de caracteres.'
          this.error = true
        } else{
          let errors = []
          if(this.new_log.title == null || this.new_log.title == ""){
            errors.push('título')
          }
          if(this.new_log.anotation == null || this.new_log.anotation == "<p></p>"){
            errors.push('descripción')
          }
          if(this.new_log.category == null){
            errors.push('categoría')
          }
          this.error_msg = errors.length>1 ? `Campos obligatorios: ${errors.join(', ')}.` : `Campo obligatorio: ${errors.join(', ')}.`
          this.error = true
        }
      }
    },
    sendLog(){
      if(this.new_log.id){
        this.putLog()
      }else{
        this.postLog()
      }
    },
    ...mapActions(['closeModal','postLog','putLog','updateLogTitle','updateLogCategory','getCategories','getStatus','updateLogStatus'])
  },
  computed: {
    ...mapState(['categories_items', 'new_log','status']),
    selectStatusColor(){
      let color = null
      let st = this.status.find(s => s.id == this.new_log.status)
      if(st){ color = st.color }
      return color
    }
  },
  mounted(){
    this.getStatus()
    if(this.new_log.id){
      this.updateLogCategory(this.new_log.category.id)
      if(this.new_log.status){
        this.updateLogStatus(this.new_log.status.id)
      }
    }
  },
};
</script>

<style lang="scss">
.v-menu__content .v-list-item--active .v-list-item__content .v-list-item__title {
  color: #181818;
    .v-list{
      .v-subheader {
        margin-left: 10px;
      }
      .v-list-item .v-list-item__content .v-list-item__title {
        font-weight: normal;
      }
    }
}
</style>
