<template>
  <div>
    <v-sheet outlined rounded>
      <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
        <div>
          <v-tooltip top color="#2222BF">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon tile :class="{ 'v-btn--active': isActive.bold() }" @click="commands.bold" v-bind="attrs" v-on="on">
                <v-icon size="20" color="#757575">mdi-format-bold</v-icon>
              </v-btn>
            </template>
            <span style="font-size: 12px">Bold</span>
          </v-tooltip>

          <v-tooltip top color="#2222BF">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon tile :class="{ 'v-btn--active': isActive.italic() }" @click="commands.italic" v-bind="attrs" v-on="on">
                <v-icon size="20" color="#757575">mdi-format-italic</v-icon>
              </v-btn>
            </template>
            <span style="font-size: 12px">Itálica</span>
          </v-tooltip>

          <v-tooltip top color="#2222BF">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon tile :class="{ 'v-btn--active': isActive.underline() }" @click="commands.underline" v-bind="attrs" v-on="on">
                <v-icon size="20" color="#757575">mdi-format-underline</v-icon>
              </v-btn>
            </template>
            <span style="font-size: 12px">Subrayado</span>
          </v-tooltip>

          <v-tooltip top color="#2222BF">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon tile :class="{ 'v-btn--active': isActive.bullet_list() }" @click="commands.bullet_list" v-bind="attrs" v-on="on">
                <v-icon size="20" color="#757575">mdi-format-list-bulleted</v-icon>
              </v-btn>
            </template>
            <span style="font-size: 12px">Lista no ordenada</span>
          </v-tooltip>

          <v-tooltip top color="#2222BF">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon tile :class="{ 'v-btn--active': isActive.ordered_list() }" @click="commands.ordered_list" v-bind="attrs" v-on="on">
                <v-icon size="20" color="#757575">mdi-format-list-numbered</v-icon>
              </v-btn>
            </template>
            <span style="font-size: 12px">Lista ordenada</span>
          </v-tooltip>

          <v-tooltip top color="#2222BF">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon tile :class="{ 'v-btn--active': isActive.blockquote() }" @click="commands.blockquote" v-bind="attrs" v-on="on">
                <v-icon size="20" color="#757575">mdi-format-quote-close</v-icon>
              </v-btn>
            </template>
            <span style="font-size: 12px">Cita</span>
          </v-tooltip>

          <v-tooltip top color="#2222BF">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon tile @click="commands.horizontal_rule" v-bind="attrs" v-on="on">
                <v-icon size="20" color="#757575">mdi-minus</v-icon>
              </v-btn>
            </template>
            <span style="font-size: 12px">Línea horizontal</span>
          </v-tooltip>

          <v-tooltip top color="#2222BF">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon tile :class="{ 'v-btn--active': isActive.code_block() }" @click="commands.code_block" v-bind="attrs" v-on="on">
                <v-icon size="20" color="#757575">mdi-code-tags</v-icon>
              </v-btn>
            </template>
            <span style="font-size: 12px">Bloque de código</span>
          </v-tooltip>

          <v-tooltip top color="#2222BF">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon tile :class="{ 'v-btn--active': isActive.link() }" @click="setUrl()" v-bind="attrs" v-on="on">
                <v-icon size="20" color="#757575">mdi-link-variant</v-icon>
              </v-btn>
            </template>
            <span style="font-size: 12px">Hipervínculo</span>
          </v-tooltip>

          <v-tooltip top color="#2222BF">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon tile @click="clickFileInput()" v-bind="attrs" v-on="on">
                <v-icon size="20" color="#757575">mdi-attachment</v-icon>
              </v-btn>
            </template>
            <span style="font-size: 12px">Subir archivos</span>
          </v-tooltip>
        </div>
      </editor-menu-bar>
      <v-divider></v-divider>
      <div class="pa-2" style="min-height: 180px" @click="focusEditor">
        <editor-content :editor="editor"/>
      </div>
      <transition name="fade" mode="out-in">
        <div class="py-2 px-3" style="height: 56px" v-if="popover">
            <v-row no-gutters align="center">
              <v-col cols="10">
                <v-text-field autofocus dense hide-details filled rounded v-model="popover_link"></v-text-field>
              </v-col>
              <v-col cols="1">
                <v-btn icon @click="closeLink()" color="#181818">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="1">
                <editor-menu-bar :editor="editor" v-slot="{ commands }">
                  <v-btn icon @click="saveLink(commands.link)" color="#2222BF">
                    <v-icon>mdi-check</v-icon>
                  </v-btn>
                </editor-menu-bar>
              </v-col>
            </v-row>
        </div>
      </transition>
    </v-sheet>
    <!-- <transition name="fade" mode="out-in">
      <p class="mb-0 mt-1 ml-2" style="color: #ff5252; font-size: 12px" v-if="textLength>500">Máximo 500 caracteres.</p>
    </transition> -->
  </div>
</template>

<script>
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import { Bold, Italic, Link, Underline, CodeBlock, Blockquote, HorizontalRule, OrderedList, BulletList, ListItem } from 'tiptap-extensions'

import { mapActions, mapState } from 'vuex';

export default {
  components: {
    EditorContent,
    EditorMenuBar
  },
  data(){
    return {
      editor: null,
      linkUrl: null,
      linkMenuIsActive: false,
      popover: false,
      popover_link: null,
      textLength: 0
    }
  },
  beforeDestroy() {
    this.editor.destroy()
  },
  methods: {
    setUrl(){
      if(this.popover){
        this.popover = false
      } else{
        this.popover = true
        const state = this.editor.state

        const { from, to } = state.selection
        let marks = []
        state.doc.nodesBetween(from, to, (node) => {
          marks = [...marks, ...node.marks]
        })

        const mark = marks.find((markItem) => markItem.type.name === 'link')

        if (mark && mark.attrs.href) {
          const presetURL = mark.attrs.href
          this.popover_link = presetURL
        }
      }
    },
    saveLink(command){
      let urlSetting = this.popover_link
      command({ href: urlSetting })
      this.closeLink()
    },
    closeLink(){
      this.popover = false
      this.popover_link = null
    },
    clickFileInput() {
      document.getElementById("searchFiles").click();
    },
    focusEditor(){
      this.editor.focus()
    },
    ...mapActions(['updateLogAnotation'])
  },
  computed: mapState(['new_log']),
  mounted(){
    this.editor = new Editor({
      disablePasteRules:true,
      extensions: [
        new Bold(),
        new Italic(),
        new Link(),
        new Underline(),
        new CodeBlock(),
        new HorizontalRule(),
        new Blockquote(),
        new OrderedList(),
        new BulletList(),
        new ListItem()
      ],
      onUpdate: ({getHTML}) => {
        let text = getHTML()
        this.updateLogAnotation(text)
        this.textLength = text.length
      },
      content: this.new_log.anotation,
    })
  }
};
</script>

<style lang="scss">
.ProseMirror:focus {
  outline: none;
}

blockquote{
  border-left: 3px solid #f1f1f8;
  padding-left: .8rem
}

pre {
  background: #f1f1f8;
  border-radius: 5px;
  overflow-x: auto;
  padding: .7rem 1rem;

  &::before {
    text-transform: uppercase;
    display: block;
    text-align: right;
    font-weight: bold;
    font-size: 0.6rem;
  }
  code {
    background-color: rgba(0, 0, 0, 0)!important;
    padding: 0!important;
  }
}
</style>
