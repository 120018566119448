<template>
  <v-app id="#app">
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
    <default-modal></default-modal>
    <v-overlay :value="loading" opacity="0.1">
      <v-progress-circular
        indeterminate
        size="64"
        color="#2222BF"
      ></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import DefaultModal from './components/App/DefaultModal'

export default {
  components: {
    DefaultModal
  },
  name: 'App',
  data: () => ({
    //
  }),
  computed: mapState(['loading']),
  methods: mapActions(['setHeader']),
  created(){
    this.setHeader()
  }
};
</script>

<style lang="scss">
*{
  font-family: 'Ubuntu', sans-serif;
  letter-spacing: normal;
}

body .v-application .v-application--wrap{
  background: #f5f5f5;
  color: #181818;
}

.fade-enter-active, .fade-leave-active{
  transition: opacity .2s ease;
}

.fade-enter, .fade-leave-to{
  opacity: 0
}

.v-application p a {
  color: #2222BF
}

a {
  text-decoration: none;
}
</style>
