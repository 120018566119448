<template>
  <div>
    <v-file-input multiple id="searchFiles" class="d-none" @change="selectFiles()" v-model="currentFiles"></v-file-input>
    <v-row>
      <v-col cols="6" md="3" v-for="(file, i) in files" :key="i">
        <v-card flat outlined class="pa-0">
          <v-img v-if="file.id" class="text-center" height="90px" :src="file.file">
            <div class="text-right">
              <v-btn icon class="ma-0 pa-0" @click="deleteExistingFile(i, file.id)">
                <v-icon small>mdi-close</v-icon>
              </v-btn>
            </div>
            <v-icon x-large color="#DEDEDE" v-if="checkFileType(file.file)">mdi-file</v-icon>
          </v-img>
          <v-img v-else class="text-center" height="90px" :src="filesUrl[i] ? filesUrl[i] : null">
            <div class="text-right">
              <v-btn icon class="ma-0 pa-0" @click="deleteFile(i, file)">
                <v-icon small>mdi-close</v-icon>
              </v-btn>
            </div>
            <v-icon x-large color="#DEDEDE" v-if="!filesUrl[i]">mdi-file</v-icon>
          </v-img>
          <v-row no-gutters align="center" style="background: #f5f5f5">
            <v-col cols="2" class="text-right"><v-icon small color="#2222BF" class="mr-1">mdi-file</v-icon></v-col>
            <v-col v-if="file.id" cols="10" class="mt-1"><p style="font-size: 11px" class="mb-0">{{oneLineName(file.file)}}</p></v-col>
            <v-col v-else cols="10" class="mt-1"><p style="font-size: 11px" class="mb-0">{{oneLineName(file.name)}}</p></v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  data: () => ({
    currentFiles: [],
    files: [],
    filesUrl: [],
    addedFiles: [],
    deletedFiles: []
  }),
  methods: {
    selectFiles(){
      this.files = [...this.files, ...this.currentFiles]
      this.getTemporaryUrl()
      if(this.new_log.id){
        this.addedFiles = [...this.addedFiles, ...this.currentFiles]
        this.updateLogFiles(this.addedFiles)
      }else{
        this.updateLogFiles(this.files)
      }
      this.currentFiles = []
    },
    oneLineName(name){
      if(window.innerWidth>960){
        if(name.length<=25){return name}
        return name.slice(0,12)+'...'+name.slice(name.length-10)
      }else{
        if(name.length<=10){return name}
        return name.slice(0,8)+'...'+name.slice(name.length-7,name.length)
      }
    },
    deleteFile(index, file){
      if(this.new_log.id){
        let addedFileIndex = this.addedFiles.findIndex(f => f == file)
        this.addedFiles.splice(addedFileIndex, 1)
      }
      this.resetFileState(index)
    },
    deleteExistingFile(index, id){
      this.deletedFiles.push(id)
      this.setFilesToDelete(this.deletedFiles)
      this.resetFileState(index)
    },
    resetFileState(index){
      this.files.splice(index, 1)
      this.filesUrl.splice(index, 1)
      this.selectFiles()
    },
    getTemporaryUrl(){
      let urls = []
      this.files.forEach(file => {
        let url = file.type && file.type.includes('image') ? URL.createObjectURL(file) : null
        urls.push(url)
      })
      this.filesUrl = urls
    },
    checkFileType(file){
      let ext = file.split(/[#?]/)[0].split('.').pop().trim()
      if(ext == "jpg" || ext == "jpeg" || ext == "png" || ext == "gif"){
        return false
      }
      return true
    },
    ...mapActions(['updateLogFiles','setFilesToDelete'])
  },
  computed: mapState(['new_log']),
  created(){
    if(this.new_log.id){ this.files = this.new_log.files}
  }

}
</script>
